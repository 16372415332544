import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ExternalRedirect from '../ExternalRedirect';

const STARS = {
	'0': 'stars_0',
	'1': 'stars_1',
	'1.5': 'stars_1_5',
	'2': 'stars_2',
	'2.5': 'stars_2_5',
	'3': 'stars_3',
	'3.5': 'stars_3_5',
	'4': 'stars_4',
	'4.5': 'stars_4_5',
	'5': 'stars_5'
};

function YelpCard({ data, images }) {
	let business = data.business;

	return (
		<ExternalRedirect href={business.url} className="yelp-info">
			<Img
				fixed={images[STARS[business.rating]].childImageSharp.fixed}
				alt={`Average Rating of ${business.rating}`}
				style={{ verticalAlign: 'text-top' }}
			/>
			<span className="yelp-info-review-count">
				{business.review_count} reviews
			</span>
			<Img
				fixed={images.yelpLogo.childImageSharp.fixed}
				alt="View on Yelp"
				style={{ top: 7 }}
			/>
		</ExternalRedirect>
	);
}

export default props => (
	<StaticQuery
		query={graphql`
			query {
				yelpLogo: file(relativePath: { eq: "yelp-outline.png" }) {
					childImageSharp {
						fixed(width: 50, height: 32) {
							...GatsbyImageSharpFixed
						}
					}
				}

				stars_0: file(relativePath: { eq: "yelp-stars/xl-0.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}

				stars_1: file(relativePath: { eq: "yelp-stars/xl-1.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_1_5: file(relativePath: { eq: "yelp-stars/xl-1_5.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_2: file(relativePath: { eq: "yelp-stars/xl-2.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_2_5: file(relativePath: { eq: "yelp-stars/xl-2_5.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_3: file(relativePath: { eq: "yelp-stars/xl-3.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_3_5: file(relativePath: { eq: "yelp-stars/xl-3_5.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_4: file(relativePath: { eq: "yelp-stars/xl-4.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_4_5: file(relativePath: { eq: "yelp-stars/xl-4_5.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
				stars_5: file(relativePath: { eq: "yelp-stars/xl-5.png" }) {
					childImageSharp {
						fixed(width: 100, height: 18) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		`}
		render={data => <YelpCard images={data} {...props} />}
	/>
);
