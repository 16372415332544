import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/layout';
import Grid from '../components/Grid';
import LocationButton from '../components/LocationButton';
import YelpCard from '../components/YelpCard';
import ExternalRedirect from '../components/ExternalRedirect';

export default function Locations(props) {
	return (
		<Layout title="Locations">
			<h1>Locations</h1>

			<Grid>
				<section aria-labelledby="vista-header">
					<h2 id="vista-header">Downtown Vista</h2>
					<p>Sit down and stay for a while</p>
					<address>
						<ExternalRedirect
							className="location"
							href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.2026543,-117.2440956,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc7698c2ac7419:0xc05733f1507efd70!8m2!3d33.2026498!4d-117.2419069"
						>
							230 main street, vista, ca
						</ExternalRedirect>
					</address>
					<br />
					<strong>Hours</strong>
					<br />
					Monday-Thursday: 11am-8pm
					<br />
					Friday-Saturday: 11am-10pm
					<br />
					Closed Sunday
					<br />
					<br />
					<a href="tel:+1760295-7993">
						<span className="sr-only">
							Call our downtown Vista location at{' '}
						</span>
						(760) 295-7993
					</a>
					<br />
					<YelpCard data={props.data.downtownYelp} />
					<LocationButton
						href="/menu-vista-downtown"
						className="frontpage-button gray-button"
					>
						Menu{' '}
						<span className="sr-only">
							for Vista Downtown location
						</span>
					</LocationButton>
					<br />
				</section>

				<section aria-labelledby="vista-foothill">
					<h2 id="vista-foothill">Vista Foothill</h2>

					<strong>Temporarily closed</strong>

					<p>Outdoor seating & take-out</p>
					<address>
						<ExternalRedirect
							className="location"
							href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.217094,-117.22392,16z/data=!4m8!1m2!2m1!1sWhen+Pigs+Fly+BBQ!3m4!1s0x0:0xceb074c420b50f1d!8m2!3d33.2176252!4d-117.2254166"
						>
							1211 e. vista way, vista, ca
						</ExternalRedirect>
					</address>
				</section>
			</Grid>
		</Layout>
	);
}

export const query = graphql`
	query {
		foothillYelp: yelp {
			business(id: "when-pigs-fly-bbq-vista-3") {
				name
				rating
				url
				review_count
			}
		}

		downtownYelp: yelp {
			business(id: "when-pigs-fly-bbq-vista-8") {
				name
				rating
				url
				review_count
			}
		}
	}
`;
